import React from "react";
import OfferCard from "./offer-card";

const Offer = ({ offerContent, cssModifer, styleClass }) => {
  return (
    <section className={`m-offer ${cssModifer ? cssModifer : ""}`}>
      <div className="_wr">
        <div className="_w m-offer__wrap">
          {offerContent.map((item, i) => (
            <OfferCard
              item={item}
              key={i}
              cssModifer="-blockchain"
              styleClass={styleClass}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Offer;
