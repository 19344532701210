import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Checkmark from "../../assets/images/other-services/checkmark.svg";

const WeBring = () => {
  const { t } = useTranslation();
  // Process Component
  const listContent = [
    {
      item: `${t("services.otherServices.listContent.items.first")}`,
    },
    {
      item: `${t("services.otherServices.listContent.items.second")}`,
    },
    {
      item: `${t("services.otherServices.listContent.items.third")}`,
    },
    {
      item: `${t("services.otherServices.listContent.items.fourth")}`,
    },
    {
      item: `${t("services.otherServices.listContent.items.fifth")}`,
    },
  ];

  return (
    <section className="m-weBring">
      <div className="_wr">
        <div className="_w m-weBring__content">
          <div className="_l6 -mb">
            <div>
              <h2 className="m-weBring__content--title">
                {t("services.otherServices.weBring.title")}
              </h2>
              <span className="a-line -red"></span>
              <p className="m-weBring__content--leftText">
                {t("services.otherServices.weBring.firstText")}
              </p>
              <p className="m-weBring__content--leftText">
                {t("services.otherServices.weBring.secondText")}
              </p>
            </div>
          </div>

          <div className="m-weBring__content--list _l6">
            {listContent.map(({ item }, i) => {
              return (
                <div className="m-weBring__content--item" key={i}>
                  {/* <div className="m-weBring__content--checkmark">
                    <Checkmark />
                  </div> */}
                  <span className="a-circle"></span>
                  <p>{item}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default WeBring;
