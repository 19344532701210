import React, { useState } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";

const LIMIT = 260;

const OfferCard = ({ item, cssModifer, styleClass }) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const renderText = (value) => {
    if (value.length > 260) {
      if (showMore) {
        return value;
      } else {
        return `${value.substring(0, LIMIT)}...`;
      }
    } else {
      return value;
    }
  };

  return (
    <div className="_m10  _l6  m-offer__itemContent">
      <div
        aria-hidden="true"
        className={`m-offer__item -fullHeight ${
          showMore === true ? "-open" : ""
        } ${styleClass ? styleClass : ""} ${
          item.text.length > 260 ? "-longText" : ""
        }`}
        onClick={() => {
          if (item.text.length > 260) {
            setShowMore(!showMore);
          } else {
            setShowMore(false);
          }
        }}

        // onClick={displayText()}
      >
        <div className="m-offer__item--top">
          <div className="m-offer__item--icon">{item.icon}</div>
          <div
            className={`m-offer__item--title ${cssModifer ? cssModifer : ""}`}
            dangerouslySetInnerHTML={{ __html: item.title }}
          />
        </div>
        <div className="m-offer__item--textContent">
          {/* {item.btnHref ? (
            <p className="m-offer__item--text">{item.text}</p>
          ) : (
            <p className="m-offer__item--text">{renderText(item.text)}</p>
          )} */}
          <p className="m-offer__item--text">{renderText(item.text)}</p>

          {item.btnHref ? (
            <div className="m-cardLinks">
              <Link
                to={item.btnHref}
                className="a-btn -tertiary -offer"
                onClick={() => setShowMore(!showMore)}
              >
                {t(showMore ? "buttons.readLess" : "buttons.readMore")}
              </Link>
              <span className="a-arrow -long"></span>
            </div>
          ) : (
            item.text.length > 260 && (
              <div className="m-cardLinks">
                <span
                  aria-hidden="true"
                  className="a-btn -tertiary -offer"
                  onClick={() => setShowMore(!showMore)}
                >
                  {t(showMore ? "buttons.readLess" : "buttons.readMore")}
                </span>
                <span className="a-arrow -long"></span>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
